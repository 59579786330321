<template>
  <div>
    <div class="d-flex justify-start align-center mb-8">
      <div>
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text mr-2"
          size="35"
        >
          <v-icon
            dense
            color="primary"
          >
            {{ icons.mdiTownHall }}
          </v-icon>
        </v-avatar>
      </div>

      <div>
        <div v-if="isLoadingInstance">
          <v-progress-circular
            color="primary"
            :size="30"
            indeterminate
          ></v-progress-circular>
        </div>

        <div v-else>
          <span class="d-block text-caption text-capitalize black--text">{{ instance.company_types_uuid.type }}</span>
          <span
            class="d-block text-subtitle-1 font-weight-bold black--text"
            style="line-height: 1.5rem !important"
          >
            {{ instance.name }}
          </span>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <v-row>
        <v-col
          xl="4"
          lg="4"
          md="4"
          sm="4"
          cols="12"
          class="py-3 offset-md-8"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            label="Search"
            single-line
            hide-details
            dense
            outlined
            @input="searchHandler($event)"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <div id="classes">
      <div>
        <div
          v-if="!isLoadingData"
          class="mb-10"
        >
          <v-row class="match-height">
            <v-col
              v-for="classesItem, index in classes"
              :key="index"
              :search="search"
              sm="6"
              md="4"
              lg="4"
              xl="4"
            >
              <v-card>
                <v-card
                  height="150"
                  color="#D14362"
                >
                  <div class="d-flex justify-center">
                    <div>
                      <v-img
                        src="../../assets/images/vector/subject.svg"
                        width="100%"
                      ></v-img>
                    </div>
                  </div>
                </v-card>

                <v-card-text
                  class="py-4"
                >
                  <div class="mb-4">
                    <h2 class="font-weight-bold mb-4 black--text">
                      {{ classesItem.name }}
                    </h2>
                  </div>

                  <div class="d-flex">
                    <div class="d-flex align-center mb-2">
                      <span class="me-3">Tingkat Kelas : </span>
                      <span class="ml-0">{{ classesItem.class_level }}</span>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="d-flex align-center mb-2">
                      <span class="me-2">Jurusan : </span>
                      <span class="ml-0">{{ classesItem.major }}</span>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="d-flex align-center mb-2">
                      <span class="me-3">Token : </span>
                      <h2 class="ml-0">
                        {{ classesItem.code }}
                      </h2>
                    </div>
                  </div>
                </v-card-text>

                <v-card-actions class="mt-1 d-flex justify-between">
                  <v-btn
                    color="primary"
                    large
                    class="px-4"
                    :to="{name: 'edit-classes', params: {uuid: classesItem.uuid}}"
                  >
                    Edit Kelas
                  </v-btn>

                  <v-btn
                    color="primary"
                    outlined
                    large
                    class="me-0 ml-auto px-3"
                    @click="confirmDestroy(classesItem.uuid)"
                  >
                    Hapus Kelas
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <v-row class="mb-5">
            <v-col
              v-for="i, index in 3"
              :key="index"
              md="4"
            >
              <v-skeleton-loader
                type="card-avatar,article, actions"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </div>

        <div v-if="classes.length < 1 && !isLoadingData">
          <div class="d-flex justify-center">
            <div
              class="pa-4 text-center"
            >
              <v-img
                src="../../assets/images/vector/cbt-empty.svg"
                class="mx-8 my-7"
              ></v-img>
              <p class="my-7">
                Belum ada Kelas
              </p>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <div
          class="mt-5 mb-5"
        >
          <v-pagination
            v-if="totalPages"
            v-model="page"
            :length="totalPages"
            total-visible="8"
            @change="paginationHandler"
          ></v-pagination>
        </div>
        <v-divider></v-divider>
      </div>

      <div class="d-flex justify-center mt-10">
        <v-btn
          to="/add-classes"
          color="primary"
          x-large
          class="px-14"
        >
          Tambah Kelas
        </v-btn>
      </div>
    </div>

    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="isLoadingButton"
      header="Hapus Kelas"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import {
  mdiDotsVertical, mdiImageMultipleOutline, mdiInformation, mdiMagnify, mdiPlus, mdiTownHall,
} from '@mdi/js'

export default {
  name: 'ClassInstance',
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      icons: {
        mdiTownHall,
        mdiPlus,
        mdiImageMultipleOutline,
        mdiDotsVertical,
        mdiInformation,
        mdiMagnify,
      },
      page: 1,
      isLoadingCard: true,
      search: '',
      filterQuery: {
        search: '',
      },
      isLoadingData: true,
      isLoadingButton: false,
      confirmDialog: false,
      instance: {},
      isLoadingInstance: false,
      serviceInstance: 'instance',
      service: 'classes',
      instanceUuid: JSON.parse(localStorage.getItem('user')).user.company_uuid,
      classes: [],
      classUuid: null,
    }
  },
  watch: {
    page: {
      handler() {
        this.getClass(this.filterQuery)
      },
    },
    search: {
      handler() {
        if (this.search.length > 3 || this.search.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.page = 1
            this.getAttendances({
              search: this.search,
            })
          }, 500)
        }
      },
    },
  },
  created() {
    this.getDetailInstance()
    this.getClass(this.filterQuery)
  },
  methods: {
    async getDetailInstance() {
      this.isLoadingInstance = true

      await this.$services.ApiServices.get(this.serviceInstance, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          this.isLoadingInstance = false
        },
        err => {
          console.error(err)
          this.isLoadingInstance = false
        },
      )
      console.log(this.instance)
      this.isLoadingInstance = false
    },

    async getClass(params = {}) {
      this.isLoadingData = true

      await this.$services.ApiServices.list(this.service, {
        ...params,
        page: this.page,
        per_page: 6,
        company_uuid: this.instanceUuid,
      }).then(
        async ({ data }) => {
          this.classes = await data.data.map((classes, index) => ({
            ...classes,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
          this.isLoadingData = false
        },
        err => {
          console.error(err)
          this.isLoadingData = false
        },
      )

      this.isLoadingData = false
    },

    confirmDestroy(uuid) {
      this.classUuid = uuid
      this.confirmDialog = true
    },

    async destroy() {
      this.isLoadingButton = true

      await this.$services.ApiServices.destroy(this.service, this.classUuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'error',
          })
          this.isLoadingButton = false
        },
        err => {
          console.log(err)
          this.isLoadingButton = false
        },
      )

      await this.getClass(this.filterQuery)
      this.isLoadingButton = false
      this.confirmDialog = false
      this.resetForm()
    },

    searchHandler(search) {
      this.filterQuery.search = search
      this.getClass(this.filterQuery)
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    resetForm() {
      this.classUuid = null
    },
  },
}
</script>

<style>
.text-delete {
  color: #e62020de !important;
}
</style>
